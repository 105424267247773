/* @see #74142 for more information */
import Tealium from '@/lib/tealium';

export default {
  methods: {
    trackAction(trackingObj) {
      if (typeof trackingObj === 'string') {
        return Tealium.link({ wt_link_id: trackingObj });
      }
      return Tealium.link(trackingObj);
    },
  },
};
